import React from 'react';
import Context from '../../assets/js/Context';
import Cookies from "universal-cookie";
import host from '../../assets/js/Host';
import axios from 'axios';
import Component from "@reactions/component";
import { Pane, Dialog} from 'evergreen-ui';
import { ToastContainer, toast } from 'react-toastify';
import { Row, Col, Card, Form, Image, Button, Table, } from 'react-bootstrap';

import Backspace from '@material-ui/icons/Backspace';
// import Lottie from 'lottie-react-web';
// import animation from '../../assets/json/clean.json';
import 'react-toastify/dist/ReactToastify.css';
import DoneIcon from '@material-ui/icons/DoneAll';
import AccountCircle from '@material-ui/icons/Instagram';
import PhoneCallback from '@material-ui/icons/PhoneCallback';
import Clear from '@material-ui/icons/Clear';
import CategoryIcon from '@material-ui/icons/Category';
import RemoveShoppingCart from '@material-ui/icons/RemoveShoppingCart';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import MoneyOff from '@material-ui/icons/MoneyOff';
import AddIcCallIcon from '@material-ui/icons/AddIcCall';
import MaterialDatatable from "material-datatable";
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import Login from '../common/login';
import * as moment from "moment-timezone";
const columns = [
    { field: "delete", name: "الغاء", options: { width: 100, filter: false, sort: false, search: false } },
    { field: "delete2", name: "الغاء مباشر", options: { width: 100, filter: false, sort: false, search: false } },

    { field: "edit", name: "تعديل السعر", options: { width: 100, filter: false, sort: false, search: false } },
    { field: "reject", name: "ارجاع الطلب", options: { width: 100, filter: false, sort: false, search: false } },
    { field: "rejected2", name: "ارجاع الطلب مباشر", options: { width: 100, filter: false, sort: false, search: false } },
    { field: "call", name: "تبليغ", options: { width: 100, filter: false, sort: false, } },

  //  { field: "uncalled", name: "تحدد كمعليق", options: { width: 100, filter: false, sort: false, } },


    { field: "done", name: "انجاح الطلب", options: { width: 100, filter: false, sort: false, } },
    { field: "updatedAt", name: "اخر تحديث", options: { filter: true, sort: false, search: false } },
    
    { field: "items", name: "المواد", options: { width: 100, filter: false, sort: false, } },

    { field: "createdAt", name: "تاريخ", options: { filter: true, sort: false, search: false } },


    { field: "status", name: "حاله الطلب", options: { filter: true, sort: false, search: false } },
    { field: "price", name: "السعر", options: { filter: true, sort: false, search: false } },
    { field: "government", name: "المحافظة", options: { filter: false, sort: false, search: false } },
    { field: "phone", name: "هاتف الزبون", options: { filter: false, search: false } },
    { field: "user_name", name: "البيج", options: { filter: false, search: false } },

    { field: "id", name: "#", options: { filter: false, } },
];
const options = {
    selectableRows: false,
    responsive: 'scroll',
    rowCursorHand: false,
    sort: false,
    print: false,
    filter: false,
    search: false,
    download: false,
    viewColumns:false,


    
    textLabels: {
        body: {
            noMatch: " لم يتم العثور على سجلات مطابقة",
            toolTip: "فرز",
        },
        pagination: {
            next: "الصفحة التالية",
            previous: "الصفحة السابقة",
            rowsPerPage: "عدد الصفوف",
            displayRows: "من",
        },
        toolbar: {
            search: "بحث",
            downloadCsv: "تنزيل",
            print: "Print",
            viewColumns: " التحكم بالاعمدة",
            filterTable: "فلتر",
        },
        filter: {
            all: "الكل",
            title: "فلتر",
            reset: "إعادة تعيين",
        },
        viewColumns: {
            title: "عرض الأعمدة",
            titleAria: "إظهار / إخفاء أعمدة الجدول",
        },

    },

}
const cookies = new Cookies();

class fromOrders extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            nameCate: '',
            Category: [],
            spin: false,
            totalCount: '',
            nameItem: '',
            priceItem: '',
            msg: '',

            image: [],
        }
    }
    getMuiTheme = () => createMuiTheme({
        overrides: {
            MaterialDatatableBodyCell: {
                root: {
                    //   backgroundColor: "#FF0000",
                    textAlign: 'right',
                },
            },
            MuiTableCell: {
                root: {
                    textAlign: 'right',

                },
                head: {
                    // backgroundColor: "#FF0000",
                }
            },

        }
    })
    onChangeD(value) {
        let count = document.getElementById(value).value;
        if (count > 0) {
            let newValue = Number(document.getElementById(value).value) - 1;
            document.getElementById(value).value = newValue
            // let re = ((item.count - count + 1) + 1)
            // document.getElementById(text).textContent = `الكمية المتبقية ${re}`

        }

    }
    Search(search) {
        var header = { "Content-Type": "application/x-www-form-urlencoded", token: cookies.get("token") };
        axios.get(host + `dashbord/form/orders/all?id=${search}`, { headers: header })
            .then(res => {
                let arr = [];
                for (let i = 0; i < res.data.data.orders.length; i++) {
                    let obj = {
                        id: res.data.data.orders[i].id,

                        user_name: res.data.data.orders[i].user.name,

                        phone: res.data.data.orders[i].phone,
                        government: ` ${res.data.data.orders[i].government} / ${res.data.data.orders[i].city}`,


                        price: res.data.data.orders[i].price,
                        status: res.data.data.orders[i].status === 5 ? "معلق" : res.data.data.orders[i].status === 0 ? "قيد الطباعة" : res.data.data.orders[i].status === 1 ? "قيد المعالجه" : res.data.data.orders[i].status === 2 ? "تم الالغاء" : res.data.data.orders[i].status === 3 ? "تم الانجاح" : "تم الرفض",
                        updatedAt: moment(res.data.data.orders[i].createdAt).tz('Asia/baghdad').format('YYYY/MM/DD'),
                        createdAt: moment(res.data.data.orders[i].createdAt).tz('Asia/baghdad').format('YYYY/MM/DD'),
                        profile: <AccountCircle style={{ cursor: 'pointer', color: '#007bff' }}
                            onClick={() => {
                                window.open(res.data.data.orders[i].account, '_blank').focus();
                            }} />,
                        items: <Component initialState={{ isShown: false }}>
                            {({ state, setState }) => (
                                <Pane>
                                    <Dialog
                                        isShown={state.isShown}
                                        title="مواد الطلب"
                                        intent="success"
                                        hasFooter={false}
                                        onCloseComplete={() => setState({ isShown: false })}
                                        confirmLabel="انجاح"
                                        cancelLabel="الغاء"
                                        onConfirm={() => {
                                            setState({ isShown: false })
                                            //       this.deleverdCate(res.data.data.orders[i].id)
                                        }}
                                    >
                                        <div>
                                            <Table striped bordered hover>
                                                <thead>
                                                    <tr style={{ textAlign: 'right' }}>


                                                        <th >العدد</th>
                                                        <th>اسم المنتج</th>

                                                    </tr>
                                                </thead>
                                                <tbody>


                                                    {res.data.data.orders[i].items.map((item, i) =>
                                                        <tr key={i} style={{ textAlign: 'right' }}>
                                                            <td>{item.count}</td>
                                                            <td>{item.name}</td>


                                                        </tr>
                                                    )}


                                                </tbody>
                                            </Table>
                                        </div>
                                    </Dialog>
                                    <CategoryIcon style={{ cursor: 'pointer', color: '#ffc107' }}
                                        onClick={() => { setState({ isShown: true }) }} />
                                </Pane>
                            )}
                        </Component>,

                        done: <Component initialState={{ isShown: false }}>
                            {({ state, setState }) => (
                                <Pane>
                                    <Dialog
                                        isShown={state.isShown}
                                        title="انجاح طلب"
                                        intent="success"
                                        onCloseComplete={() => setState({ isShown: false })}
                                        confirmLabel="انجاح"
                                        cancelLabel="الغاء"
                                        onConfirm={() => {
                                            setState({ isShown: false })
                                            this.deleverdCate(res.data.data.orders[i].id)
                                        }}
                                    >
                                        <span id='msgDelete'> هل انت متأكد من انجاح الطلب رقم {res.data.data.orders[i].id}</span>
                                    </Dialog>
                                    <DoneIcon style={{ cursor: 'pointer', color: '#28a745' }}
                                        onClick={() => { setState({ isShown: true }) }} />
                                </Pane>
                            )}
                        </Component>,

                        call: <Component initialState={{ isShown: false }}>
                            {({ state, setState }) => (
                                <Pane>
                                    <Dialog
                                        isShown={state.isShown}
                                        title={`تبليغ على الطلب ${res.data.data.orders[i].id}`}
                                        onCloseComplete={() => setState({ isShown: false })}
                                        confirmLabel="تبليغ"
                                        cancelLabel="الغاء"
                                        onConfirm={() => {
                                            this.call(res.data.data.orders[i].id)
                                            setState({ isShown: false })
                                        }}
                                    >
                                        <div>
                                            <div id='inputAddContiner'>
                                                <div id={'ContinerInPut'} >
                                                    <label>سبب الرفض </label>
                                                    <input autoComplete='off' type='text' placeholder='سبب الرفض ' id='InputTExtDash1'
                                                        onChange={(e) => this.setState({ msg: e.target.value })} />
                                                </div>
                                            </div>

                                        </div>
                                    </Dialog>
                                    <PhoneCallback style={{ cursor: 'pointer', color: '#000000' }}
                                        onClick={() => { setState({ isShown: true }) }} />
                                </Pane>
                            )}
                        </Component>,
                        edit: <Component initialState={{ isShown: false }}>
                            {({ state, setState }) => (
                                <Pane>
                                    <Dialog
                                        isShown={state.isShown}
                                        title='تعديل السعر'
                                        onCloseComplete={() => setState({ isShown: false })}
                                        confirmLabel="تعديل"
                                        cancelLabel="الغاء"
                                        onConfirm={() => {
                                            this.EditItem(res.data.data.orders[i].id)
                                            setState({ isShown: false })
                                        }}
                                    >
                                        <div>
                                            <div id='inputAddContiner'>
                                                <div id={'ContinerInPut'} >
                                                    <label>السعر </label>
                                                    <input autoComplete='off' type='number' placeholder='السعر ' id='InputTExtDash1'
                                                        onChange={(e) => this.setState({ priceItem: e.target.value })} />
                                                </div>
                                            </div>

                                        </div>
                                    </Dialog>
                                    <MoneyOff style={{ cursor: 'pointer', color: '#4752d1' }}
                                        onClick={() => { setState({ isShown: true }) }} />
                                </Pane>
                            )}
                        </Component>,
                        reject: <Component initialState={{ isShown: false }}>
                            {({ state, setState }) => (
                                <Pane>
                                    <Dialog
                                        isShown={state.isShown}
                                        title='ارجاع الطلب'
                                        intent="warning"
                                        onCloseComplete={() => setState({ isShown: false })}
                                        confirmLabel="راجع"
                                        cancelLabel="الغاء"
                                        onConfirm={() => {
                                            let check = this.recjeted(res.data.data.orders[i].items, res.data.data.orders[i].id)
                                            setState({ isShown: check })
                                        }}
                                    >
                                        <div>

                                            <Row >
                                                <Col >
                                                    <center>
                                                        <h5 style={{ margin: "20px" }}> المتنجات اللتي تم اختيارها في طلب رقم {res.data.data.orders[i].id}</h5></center>
                                                    {res.data.data.orders[i].items.map((item, i) => (
                                                        <Row className="justify-content-md-center" style={{ margin: '15px' }} key={i}>


                                                            <div>
                                                                <Card style={{ width: '300px' }}>

                                                                    <Card.Body>
                                                                        <center><b>{item.name}</b></center>
                                                                        <br></br>
                                                                        <Row >
                                                                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                                                <Image style={{ width: '100px', height: '100px' }} src="https://www.dhresource.com/0x0/f2/albu/g16/M01/66/68/rBVa32AE8WKAblemAAMk9yjY9Jk361.jpg" rounded />
                                                                                <div style={{ display: 'flex', marginLeft: '20px', marginBottom: '20px' }}>
                                                                                    <Button style={{ width: '50px', height: '50px' }} variant="danger" onClick={() => {

                                                                                        this.onChangeD(`item${item.id}`)
                                                                                    }}><b>-</b></Button>
                                                                                    <Form.Control style={{ width: '50px', height: '50px', fontWeight: 'bold', textAlign: 'center' }} value={item.count}
                                                                                        id={`item${item.id}`}
                                                                                        isabled />
                                                                                    <Button style={{ width: '50px', height: '50px' }} variant="success" disabled onClick={() => {
                                                                                        // this.onChangeI(item.name, item, `${i}count`)

                                                                                    }}><b>+</b></Button>

                                                                                </div>

                                                                            </div>
                                                                            <center>
                                                                                <Form.Text
                                                                                // id={`${i}count`}
                                                                                >
                                                                                    {/* {`  الكمية المتبقية ${item.count}`} */}
                                                                                </Form.Text>
                                                                            </center>
                                                                        </Row>
                                                                        <hr></hr>
                                                                    </Card.Body>
                                                                </Card>
                                                            </div>




                                                        </Row>
                                                    ))}
                                                    <br />
                                                    <center>
                                                        <Form.Group className="mb-5" controlId="formGroupEmail" dir="rtl">
                                                            <Form.Label style={{ fontSize: '20px', fontWeight: 'bold' }} >السعر الجديد مع التوصيل</Form.Label>
                                                            <Form.Control type="number" id="newPrice" style={{ width: "60%" }} placeholder="ادخل سعر التوصيل الجديد" />
                                                        </Form.Group>
                                                    </center>
                                                </Col>
                                            </Row>



                                        </div>
                                    </Dialog>
                                    <Clear style={{ cursor: 'pointer', color: '#ff0a0a' }}
                                        onClick={() => { setState({ isShown: true }) }} />
                                </Pane>
                            )}
                        </Component>,
                        rejected2: <Component initialState={{ isShown: false }}>
                            {({ state, setState }) => (
                                <Pane>
                                    <Dialog
                                        isShown={state.isShown}
                                        title={`ارجاع مباشر`}
                                        intent="danger"
                                        onCloseComplete={() => setState({ isShown: false })}
                                        confirmLabel="ارجاع"
                                        cancelLabel="الغاء"
                                        onConfirm={() => {
                                            setState({ isShown: false })
                                            this.recjet2(res.data.data.orders[i].id)
                                        }}
                                    >
                                        <span id='msgDelete'> {` هل انت متأكد من ارجاع الطلب ${res.data.data.orders[i].id} بدون ارجاع القطع ؟`}</span>
                                    </Dialog>
                                    <RemoveShoppingCart style={{ cursor: 'pointer', color: '#ec7373' }}
                                        onClick={() => { setState({ isShown: true }) }} />
                                </Pane>
                            )}
                        </Component>,
                        uncalled: <Component initialState={{ isShown: false }}>
                            {({ state, setState }) => (
                                <Pane>
                                    <Dialog
                                        isShown={state.isShown}
                                        title={`تعليق الطلب`}
                                        intent="danger"
                                        onCloseComplete={() => setState({ isShown: false })}
                                        confirmLabel="تعليق"
                                        cancelLabel="الغاء"
                                        onConfirm={() => {
                                            setState({ isShown: false })
                                            this.uncalled(res.data.data.orders[i].id)
                                        }}
                                    >
                                        <span id='msgDelete'> {` هل انت متأكد من تعليق الطلب ${res.data.data.orders[i].id}    ؟`}</span>
                                    </Dialog>
                                    <AddIcCallIcon style={{ cursor: 'pointer', color: '#8535dc' }}
                                        onClick={() => { setState({ isShown: true }) }} />
                                </Pane>
                            )}
                        </Component>,
                        delete: <Component initialState={{ isShown: false }}>
                            {({ state, setState }) => (
                                <Pane>
                                    <Dialog
                                        isShown={state.isShown}
                                        title={`حذف الطلب رقم ${res.data.data.orders[i].id}`}
                                        intent="danger"
                                        onCloseComplete={() => setState({ isShown: false })}
                                        confirmLabel="حذف"
                                        cancelLabel="الغاء"
                                        onConfirm={() => {
                                            setState({ isShown: false })
                                            this.DeleteCate(res.data.data.orders[i].id)
                                        }}
                                    >
                                        <span id='msgDelete'> هل متأكد ؟ حذف الطلب رقم {res.data.data.orders[i].id}</span>
                                    </Dialog>
                                    <DeleteForeverIcon style={{ cursor: 'pointer', color: '#ec7373' }}
                                        onClick={() => { setState({ isShown: true }) }} />
                                </Pane>
                            )}
                        </Component>,
                        delete2: <Component initialState={{ isShown: false }}>
                            {({ state, setState }) => (
                                <Pane>
                                    <Dialog
                                        isShown={state.isShown}
                                        title={`الغاء مباشر`}
                                        intent="danger"
                                        onCloseComplete={() => setState({ isShown: false })}
                                        confirmLabel="تأكيد"
                                        cancelLabel="الغاء"
                                        onConfirm={() => {
                                            setState({ isShown: false })
                                            this.DeleteOrder(res.data.data.orders[i].id)
                                        }}
                                    >
                                        <span id='msgDelete'> هل انت متأكد تريد الغاء المباشر للطلب  {res.data.data.orders[i].id} ؟</span>
                                    </Dialog>
                                    <Backspace style={{ cursor: 'pointer', color: '#550d0d' }}
                                        onClick={() => { setState({ isShown: true }) }} />
                                </Pane>
                            )}
                        </Component>,
                    };
                    arr.push(obj);


                }
                this.setState({
                    Category: arr, spin: false
                });
            })
            .catch(error => { console.log(error.response) })

    }
    DeleteCate(id) {

        var header = { "Content-Type": "application/x-www-form-urlencoded", token: cookies.get("token") };
        axios.delete(host + `users/all/ordrers/${id}`, { headers: header })
            .then(response => {

                toast('تم الحذف بنجاح', {
                    position: "bottom-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true
                });
                this.componentDidMount();
            })
            .catch(error => { console.log(error) })
    }
    DeleteOrder(id) {

        var header = { "Content-Type": "application/x-www-form-urlencoded", token: cookies.get("token") };
        axios.post(host + `users/orders/cencald/${id}`, { headers: header })
            .then(response => {

                toast('تم الغاء الطلب المباشر', {
                    position: "bottom-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true
                });
                this.componentDidMount();
            })
            .catch(error => { console.log(error) })
    }

    uncalled(id) {

        var header = { "Content-Type": "application/x-www-form-urlencoded", token: cookies.get("token") };
        axios.post(host + `users/form/orders/uncalled/${id}`, { headers: header })
            .then(response => {

                toast('تم التبلغ بنجاح', {
                    position: "bottom-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true
                });
                this.componentDidMount();
            })
            .catch(error => { console.log(error) })
    }
    recjet2(id) {

        var header = { "Content-Type": "application/x-www-form-urlencoded", token: cookies.get("token") };
        axios.post(host + `users/orders/rejcted/${id}`, { headers: header })
            .then(response => {

                toast('تم الارجاع بنجاح', {
                    position: "bottom-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true
                });
                this.componentDidMount();
            })
            .catch(error => { console.log(error) })
    }
    deleverdCate(id) {


        axios.post(host + `dashbord/form/orders/deleverd`, { orders_id: id.toString() })
            .then(response => {

                toast('تم انجاح الطلب ', {
                    position: "bottom-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true
                });
                this.componentDidMount();
            })
            .catch(error => { console.log(error) })
    }
    EditItem(id) {
        var header = { "Content-Type": "application/x-www-form-urlencoded", token: cookies.get("token") };
        let formData = new FormData();
        formData.append("price", this.state.priceItem);
        axios({ url: host + `dashbord/form/orders/edit/${id}`, method: "PUT", data: formData, headers: header })
            .then(response => {
                this.Search();
                toast('تم التعديل بنجاح', {
                    position: "bottom-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true
                });
            })
            .catch(error => { console.log(error.response.data.message) })
    }

    call(id) {
        var header = { "Content-Type": "application/x-www-form-urlencoded", token: cookies.get("token") };
        let formData = new FormData();
        formData.append("msg", this.state.msg);
        axios({ url: host + `users/orders/call/${id}`, method: "POST", data: formData, headers: header })
            .then(response => {
                this.Search();
                toast('تم التلبيغ', {
                    position: "bottom-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true
                });
            })
            .catch(error => { console.log(error) })
    }
    AddItem() {
        var header = { "Content-Type": "application/x-www-form-urlencoded", token: cookies.get("token") };
        let formData = new FormData();
        formData.append("name", this.state.nameItem);
        formData.append("count", 1);
        formData.append("price", this.state.priceItem);
        formData.append("hasCount", false);
        formData.append("category_id", this.props.match.params.id);
        formData.append("file", this.state.image[0]);
        axios({ url: host + `dashbord/item`, method: "POST", data: formData, headers: header })
            .then(response => {
                this.setState({ spinerBtn: false })
                this.componentDidMount();
                toast('تم اضافة المادة بنجاح', {
                    position: "bottom-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true
                });
            })
            .catch(error => { console.log(error.response.data.message) })
    }
    recjeted(item, id) {
 
        let data = []
        // const sum = item.reduce((a, {watt}) => a + watt, 0);

        for (let index = 0; index < item.length; index++) {

            let count = document.getElementById(`item${item[index].id}`).value
            let obj = {
                id: item[index].id,
                count: Number(count)
            }
            data.push(obj)
        }
        const Oldsum = item.reduce((a, { count }) => a + count, 0);
        const newsum = data.reduce((a, { count }) => a + count, 0);
        let check = 0;
        let newPrice = document.getElementById('newPrice').value;

        if (Oldsum > newsum) {
            check = 1;


            if (!newPrice) {
                toast.warn('عليك ادخال سعر الطلب الجديد', {
                    position: "bottom-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true
                });
                return -1;

            }
        }
        var header = { "Content-Type": "application/x-www-form-urlencoded", token: cookies.get("token") };
        let formData = new FormData();

        if (check === 1) {
            formData.append("check", 1);
            formData.append("price", newPrice);
        } else {
            formData.append("check", 0);
        }
        formData.append("data", JSON.stringify(data));
        axios({ url: host + `dashbord/form/orders/rejected/${id}`, method: "post", data: formData, headers: header })
            .then(response => {
             //   this.componentDidMount(id);
                toast('تم ارجاع الطلب', {
                    position: "bottom-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true
                });
                return true;
            })
            .catch(error => { 
            
            })

    }
    render() {
        if (cookies.get("token")) {
            return (
                <Context.Consumer>
                    {ctx => {
                        return (
                            <div >
                                <div id="searchDiv">
                                    <input type="text" placeholder="بحث" id='searchINN' onKeyPress={(e) => {

                                        if (e.key === "Enter") {
                                            if (e.target.value) {
                                                this.Search(document.getElementById('searchINN').value)
                                            } else {
                                                this.Search('ss')

                                            }
                                        }
                                    }} />
                                    <button onClick={(e) => {
                                        let Search = document.getElementById('searchINN').value
                                        if (Search) {
                                            this.Search(document.getElementById('searchINN').value)
                                        } else {
                                            this.Search('ss')
                                        }

                                    }} id="serchbtn">بحث</button>
                                </div>

                                <div>

                                </div>
                                <div className='DataTableContiner'>
                                    <MuiThemeProvider

                                        theme={this.getMuiTheme()}>
                                        <MaterialDatatable data={this.state.Category} columns={columns} options={options} />
                                    </MuiThemeProvider>
                                </div>
                                <ToastContainer
                                    position="bottom-left"
                                    autoClose={10000}
                                    hideProgressBar={false}
                                    newestOnTop={false}
                                    closeOnClick
                                    rtl={false}
                                    pauseOnVisibilityChange
                                    draggable
                                    pauseOnHover
                                />
                            </div>
                        )
                    }
                    }
                </Context.Consumer>
            )
        }
        else {
            return (
                <Login />
            )
        }
    }
}


export default fromOrders;